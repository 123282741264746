<template>
  <div class="grip">
    <div class="grid_4">
      <div class="grid_4_2"><img src="../assets/index/qkqk2.png"/></div>

      <div class="grid_4_1">

        <div class="xinxi_8">
          <span class="xinxi_9" style="font-family:PingFangSC-Regular,PingFang SC;">解决方案</span>
        </div>
        <div class="xinxi_10">
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipTSP">TSP平台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipSchemeFleet">车队管理&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipEnergy">新能源监控(RTM)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipOTA">OTA升级</el-button>
        </div>
        <div class="xinxi_11">
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipSchemeVideo">视频监控平台&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipDirectMall">直销商城&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipBigData">大数据&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipPartsMall">配件商城&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipForum">论坛</el-button>
        </div>

        <div class="xinxi_12">
          <span class="xinxi_13" style="font-family:PingFangSC-Regular,PingFang SC;">项目案例</span>
        </div>
        <div class="xinxi_14">
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipPassenger">乘用车车联网&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipFleet">车队管理系统&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" class="xinxi_17" @click="skipVideo">视频监控平台&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
        </div>
        <div class="xinxi_15">
          <el-button type="text" style="font-family:PingFangSC-Regular,PingFang SC;" @click="skiCommercial" class="xinxi_16">商用车车联网</el-button>
        </div>
      </div>

    </div>

    <div class="grid_5">
      <div class="bottom_1">
        <span class="bottom_2">© 2019 苏州英思唯智能科技有限公司</span>
      </div>
      <div class="bottom_3"><img src="../assets/index/0c57706.jpg" style="width: 20px;height: 20px;margin-left: 54px;"/></div>
      <div class="bottom_4">
        <span class="bottom_5">苏公网安备 32059002002878号</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "partsMall",
  beforeRouteEnter(to, from, next) {
    // 添加背景色 margin:0;padding:0是为了解决vue四周有白边的问题
    document.querySelector('body').setAttribute('style', 'margin:0;padding:0px;')
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector('body').setAttribute('style', '')
    next()
  },
  methods:{
    skipAbout(){
      this.$router.push('/about');
    },
    skipHome(){
      this.$router.push('/');
    },
    skipPassenger(){
      this.$router.push('/passenger')
    },
    skipVideo(){
      this.$router.push('/video')
    },
    skipTSP(){
      this.$router.push('/TSP')
    },
    skipFleet(){
      this.$router.push('/fleet')
    },
    skiCommercial(){
      this.$router.push('/commercial')
    },
    skipSchemeFleet(){
      this.$router.push('/schemeFleet')
    },
    skipEnergy(){
      this.$router.push('/energy')
    },
    skipOTA(){
      this.$router.push('/OTA')
    },
    skipSchemeVideo(){
      this.$router.push('/schemeVideo')
    },
    skipBigData(){
      this.$router.push('/data')
    },
    skipDirectMall(){
      this.$router.push('/directMall')
    },
    skipPartsMall(){
      this.$router.push('/partsMall')
    },
    skipForum(){
      this.$router.push('/forum')
    },
  },

}
</script>

<style scoped>
.grip{
  width: 100%;
  height: 472px;
}
.grid_4{
  width: 100%;
  height: 402px;
  background: #222222;
}
.grid_4_1{
  display: inline-block;
  vertical-align: middle;
  margin-top: -200px;
  margin-left: -366px;
}
.grid_4_2{
  margin-left: -578px;
  padding-top: 86px;
}
.xinxi_8{
  /*width: 1002px;*/
  height: 40px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  /*line-height: 47px;*/
  /*display: inherit;*/
  margin-left: 808px;
  margin-top: -7px;
}
.xinxi_9{
  margin-top: -162px;
}
.xinxi_10{
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 40px;
  margin-left: 1120px;
  margin-top: -18px;
}
.xinxi_11{
  height: 70px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 4px;
  margin-left: 1119px;
  margin-top: -11px;
}
.xinxi_12{
  height: 0px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 6px;
  margin-left: 808px;
  margin-top: -6px;
}
.xinxi_13{
  margin-top: -162px;
}
.xinxi_14{
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 48px;
  margin-left: 1119px;
  margin-top: 5px;
}
.xinxi_15{
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.65);
  line-height: 66px;
  margin-left: 828px;
  margin-top: -18px;
}
.xinxi_16{
  margin-left: -14px;
  color: rgba(255,255,255,0.65);
}
.xinxi_17{
  color: rgba(255,255,255,0.65);
}
.bottom_1{
  /*width: 50%;*/
  margin-left: -944px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 72px;
}
.bottom_2{
  color: rgba(255,255,255,0.55);
  margin-left: 56px;
}
.bottom_3{
  margin-left: 683px;
}
.bottom_4{
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 20px;
  margin-left: 923px;
  margin-top: -22px;
}
.bottom_5{
  color: rgba(255, 255, 255, 0.55);
  margin-left: 54px;
}
.grid_5{
  width: 100%;
  height: 70px;
  background: #111111;
}
</style>