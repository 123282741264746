<template>
  <div ref="dragDiv" class="float-drag-button">

    <div class="left11">
      <div class="text_1">
        <span class="text_2">合作咨询</span>
      </div>
      <el-tooltip class="item" effect="light" content="Left Center 提示文字" placement="left">
        <div class="left1" slot="content">
          <div style="width:56px;height:20px;font-size:14px;color:#176CB4;margin-left:5px;font-weight:400;">联系电话</div> <br/>
          <div style="width:112px;height:25px;margin-left:-13px;margin-top:-23px;">15524891226</div>
          <div class="left4" @click="copyOrderId1('15524891226')">
            <img style="width: 14px;height: 13px" alt="" src="../assets/index/hzzx_fz.png"/>
          </div>
        </div>
        <el-button :style="backgroundDiv1" style="width: 68px;height: 68px;"></el-button>
      </el-tooltip>

      <el-tooltip class="item" effect="light" content="Left Center 提示文字" placement="left">
        <div class="left2" slot="content">
          <div style="width:56px;height:20px;font-size:14px;font-family: PingFangSC-Medium, PingFang SC;
          color:#176CB4;margin-left:5px;font-weight: 500;">邮箱地址</div>
          <br/>
          <div style="width:190px;height:25px;margin-left:-26px;font-family: PingFangSC-Medium, PingFang SC;
          margin-top:-23px;font-weight: 500;">company@esvtek.com</div>
          <div class="left3" @click="copyOrderId2('company@esvtek.com')">
            <img style="width: 14px;height: 13px" alt="" src="../assets/index/hzzx_fz.png"/>
          </div>
        </div>
        <el-button :style="backgroundDiv2" style="width: 68px;height: 68px;"></el-button>
      </el-tooltip>
    </div>

  </div>
</template>

<script>
export default {
  name: "suspendSidebar",
  beforeRouteEnter(to, from, next) {
    // 添加背景色 margin:0;padding:0是为了解决vue四周有白边的问题
    document.querySelector('body').setAttribute('style', 'margin:0;padding:0px;')
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector('body').setAttribute('style', '')
    next()
  },
  data() {
    return {
      backgroundDiv1: {
        backgroundImage: 'url(' + require('@/assets/index/hzzx_ffrx@2x.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv2: {
        backgroundImage: 'url(' + require('@/assets/index/hzzx_qyyx@2x.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 获取DOM元素
      let dragDiv = this.$refs.dragDiv;
      // 缓存 clientX clientY 的对象: 用于判断是点击事件还是移动事件
      let clientOffset = {};
      // 绑定鼠标按下事件
      dragDiv.addEventListener("mousedown", (event) => {
        let offsetX = dragDiv.getBoundingClientRect().left; // 获取当前的x轴距离
        let offsetY = dragDiv.getBoundingClientRect().top; // 获取当前的y轴距离
        let innerX = event.clientX - offsetX; // 获取鼠标在方块内的x轴距
        let innerY = event.clientY - offsetY; // 获取鼠标在方块内的y轴距
        console.log(offsetX, offsetY, innerX, innerY);
        // 缓存 clientX clientY
        clientOffset.clientX = event.clientX;
        clientOffset.clientY = event.clientY;
        // 鼠标移动的时候不停的修改div的left和top值
        document.onmousemove = function (event) {
          dragDiv.style.left = event.clientX - innerX + "px";
          dragDiv.style.top = event.clientY - innerY + "px";
          // dragDiv 距离顶部的距离
          let dragDivTop = window.innerHeight - dragDiv.getBoundingClientRect().height;
          // dragDiv 距离左部的距离
          let dragDivLeft = window.innerWidth - dragDiv.getBoundingClientRect().width;
// 边界判断处理
          // 1、设置左右不能动
          dragDiv.style.left = dragDivLeft + "px";
          // 2、超出顶部处理
          if (dragDiv.getBoundingClientRect().top <= 0) {
            dragDiv.style.top = "0px";
          }
          // 3、超出底部处理
          if (dragDiv.getBoundingClientRect().top >= dragDivTop) {
            dragDiv.style.top = dragDivTop + "px";
          }
        };
        // 鼠标抬起时，清除绑定在文档上的mousemove和mouseup事件；否则鼠标抬起后还可以继续拖拽方块
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      }, false);
      // 绑定鼠标松开事件
      dragDiv.addEventListener('mouseup', (event) => {
        let clientX = event.clientX;
        let clientY = event.clientY;
        if (clientX === clientOffset.clientX && clientY === clientOffset.clientY) {
          console.log('click 事件');
        } else {
          console.log('drag 事件');
        }
      })
    });
  },
  methods: {

    copyOrderId1(orderId) {
      var input = document.createElement("input"); // 创建input对象
      input.value = orderId; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制联系电话成功');
    },

    copyOrderId2(orderId) {
      var input = document.createElement("input"); // 创建input对象
      input.value = orderId; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制邮箱地址成功！');
    },

  }
}
</script>
<style lang="css">
::v-deep .el-tooltip__popper {
  box-shadow: 0px 4px 8px 0px #cbcbcb !important;
  border-radius: 4px !important;
  border: 1px solid white !important;
}
  .el-tooltip__popper.is-light {
    box-shadow: 0px 4px 8px 0px #cbcbcb;
    border-radius: 4px;
    border: 1px solid white !important;
  }
</style>

<style scoped>

.box{
  width: 100px;
}
.left{
  float: left;
  width: 60px;
}
::v-deep .el-button el-tooltip item el-button--default{
  margin-left: 0px !important;
}
::v-deep .el-button{
  margin-left: 10px;
  border: 1px #176CB4;
  border-radius: 6px;
}
::v-deep .el-tooltip__popper{
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px #CBCBCB;
  border-radius: 4px;
}
.float-drag-button {
  position:fixed;
  /*right: -140px;*/
  right: -14px;
  top: 40%;
  z-index: 6666;
  width: 0px;
  padding-right: 255px;
  opacity: 1;
  border-radius: 8px 0px 0px 8px;
  cursor: move;
}
.span {
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  user-select: none;
}

.text_1{
  width: 148%;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #176CB4;
  line-height: 22px;
  margin-top: -25px;
  margin-left: 8px;
}
.text_2{
  float: right;
  padding-right: 48px;
}
.left11{
  display: inline-grid;
}
.left1{
  width: 125px;
  height: 46px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  /*box-shadow: 0px 4px 8px 0px #CBCBCB;*/
  /*border-radius: 4px;*/
}
.left2{
  width: 175px;
  height: 46px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
}
.left3{
  width: 11px;
  height: 11px;
  /*background: #176DB4;*/
  /*background-color: red;*/
  margin-left: 160px;
  margin-top: -35px;
}
.left4{
  width: 11px;
  height: 11px;
  /*background: #176DB4;*/
  /*background-color: red;*/
  margin-left: 110px;
  margin-top: -37px;
}
</style>