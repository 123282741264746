<template>
  <div class="scroll" v-if="suspendTop">
    <div class="nav" :class="navBarFixed == true ? 'navBarWrap' :''">
      <div class="login">
<!--        <img class="login-image" alt="" src="../assets/index/logo-原色@2x.png">-->
        <div class="login-four">
          <div class="login-image-1">
            <img alt="" src="../assets/index/logo-原色@2x.png">
          </div>

          <el-button type="text" class="login-four-1" @click="skipHome" >首页</el-button>

          <el-dropdown type="primary">
            <el-button type="text" class="login-four-2">解决方案</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipTSP">TSP平台</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipSchemeFleet">车队管理</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipEnergy">新能源监控(RTM)</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipOTA">OTA升级</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipSchemeVideo">视频监控平台</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipBigData">大数据</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipDirectMall">直销商城</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipPartsMall">配件商城</div></el-dropdown-item>
              <el-dropdown-item style="text-align:center;font-family:PingFangSC-Regular,PingFang SC;"><div @click="skipForum">论坛</div></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown type="primary">
            <el-button type="text" class="login-four-3">项目案例</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="text-align: center;"><div
                  @click="skipPassenger">乘用车车联网</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div
                  @click="skipFleet">车队管理系统</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div
                  @click="skipVideo">视频监控平台</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div
                  @click="skiCommercial">商用车车联网</div></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button type="text" class="login-four-4" @click="skipAbout">关于英思唯</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "suspendTop",
  beforeRouteEnter(to, from, next) {
    // 添加背景色 margin:0;padding:0是为了解决vue四周有白边的问题
    document.querySelector('body').setAttribute('style', 'margin:0;padding:0px;')
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector('body').setAttribute('style', '')
    next()
  },
  data () {
    return {
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/index/banner1.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      navBarFixed: false,
      suspendTop: false,
    }
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    watchScroll() {
      var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      console.log(scrollTop)
      //  当滚动超过 90 时，实现吸顶效果
      if (scrollTop > 70) {
        this.navBarFixed = true;
        this.suspendTop = true
      } else {
        this.navBarFixed = false;
        this.suspendTop = false
      }
    },
    skipAbout(){
      this.$router.push('/about');
    },
    skipHome(){
      this.$router.push('/');
    },
    skipPassenger(){
      this.$router.push('/passenger')
    },
    skipVideo(){
      this.$router.push('/video')
    },
    skipTSP(){
      this.$router.push('/TSP')
    },
    skipFleet(){
      this.$router.push('/fleet')
    },
    skiCommercial(){
      this.$router.push('/commercial')
    },
    skipSchemeFleet(){
      this.$router.push('/schemeFleet')
    },
    skipEnergy(){
      this.$router.push('/energy')
    },
    skipOTA(){
      this.$router.push('/OTA')
    },
    skipSchemeVideo(){
      this.$router.push('/schemeVideo')
    },
    skipBigData(){
      this.$router.push('/data')
    },
    skipDirectMall(){
      this.$router.push('/directMall')
    },
    skipPartsMall(){
      this.$router.push('/partsMall')
    },
    skipForum(){
      this.$router.push('/forum')
    },
  }
}
</script>

<style scoped>
.button{
  outline: none;
  background: rgba(0,0,0,0);
  border-radius: 4px;
  border: 1px solid red;
  opacity: 0.75;
}
.login{
  display: flex;
  width: 1140px;
  height: 80px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.login-four{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 11px;
  left: 55px;
  top: 0;
  width: 100%;
}
.login-four-1{
  width: 40px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: 	#A8A8A8;
  line-height: 27px;
  margin-left: 558px;
  /*opacity: 0.7;*/
}
.login-four-2{
  width: 80px;
  height: 40px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A8A8A8;
  line-height: 45px;
  margin-left: 56px;
  text-align: center;
  /*opacity: 0.7;*/
}
.login-four-3{
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A8A8A8;
  line-height: 28px;
  margin-left: 56px;
  display: flex;
  /*opacity: 0.7;*/
}
.login-four-4{
  width: 100px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-left: 56px;
  color: #A8A8A8;
  line-height: 28px;
  /*opacity: 0.7;*/
}
.login-image{
  height: 36px;
  position: absolute;
  width: 9%;
  top: 30px;
  left: 411px;
}
/*.scroll{*/
/*  height:2000px;*/
/*}*/
.header {
  height: 90px;
  background: red;
  text-align: center;
  color: #fff;
}
.nav{
  padding:15px;
  width:100%;
  box-sizing: border-box;
  background:white;
  /*text-align: center;*/
}
.navBarWrap {
  position:fixed;
  top:0;
  z-index:999;
  height: 82px;
}
.el-button--text.is-active,
.el-button--text:active {
  color: black;
}

.el-button--text:focus,
.el-button--text:hover {
  color: black;
}
::v-deep .login-image[data-v-592ff093] {
  height: 36px;
  position: absolute;
  width: 9%;
  top: 30px;
  left: 380px;
}
.login-image-1{
  width: 118px;
  height: 4px;
  margin-left: -108px;
}
</style>