<template>
  <div class="home-page">
    <div class="home-page-picture" alt="">

      <div id="app">
        <div class="SwiperBox" ref="SwiperBox" @mouseenter="MouseFun('移入')" @mouseleave="MouseFun('移出')">
          <!-- 图片 -->
          <div class="imgBox" :style="{left:`-${leftVal}px`,transition:`${ition}s`}">
            <img :src="item.imgUrl" v-for="(item,index) in imgList" :key="index" />
            <!-- 复制第一张放到最后,以实现无缝无线循环滚动效果 -->
            <img :src="imgList[0].imgUrl" alt="">
          </div>
          <!-- 下方指示点容器 -->
          <div class="instBox">
            <div @click="instFun(index)" v-for="(item,index) in imgList.length" :key="index"
                 :class="['inst',index==imgShow?'instActv':'']">
            </div>
          </div>
        </div>
      </div>

      <div class="login">
        <div class="login-four">
          <div class="login-image-1">
            <img alt="" src="../assets/index/logo111.png">
          </div>
          <el-button type="text" class="login-four-1" @click="skipHome" >首页</el-button>
          <el-dropdown type="primary">
            <el-button type="text" class="login-four-2">解决方案</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="text-align: center;"><div @click="skipTSP">TSP平台</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipSchemeFleet">车队管理</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipEnergy">新能源监控(RTM)</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipOTA">OTA升级</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipSchemeVideo">视频监控平台</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipBigData">大数据</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipDirectMall">直销商城</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipPartsMall">配件商城</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div @click="skipForum">论坛</div></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown type="primary">
            <el-button type="text" class="login-four-3">项目案例</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="text-align: center;"><div
                  @click="skipPassenger">乘用车车联网</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div
                  @click="skipFleet">车队管理系统</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div
                  @click="skipVideo">视频监控平台</div></el-dropdown-item>
              <el-dropdown-item style="text-align: center;"><div
                  @click="skiCommercial">商用车车联网</div></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button type="text" class="login-four-4" @click="skipAbout">关于英思唯</el-button>
        </div>

      </div>

      <div class="login-center">
        <span class="login-center-1">让数据更智能</span>
        <span class="login-center-2">助力企业健康发展</span>
      </div>

    </div>

    <div class="home-page-index">
      <div class="home-page-first">
        <div class="home-page-first-1">
          <span>解决方案</span>
        </div>
        <div class="home-page-first-2">
          <span>--Solutions--</span>
        </div>

        <div class="grid_1 flex-row">
          <div class="section_3 flex-col" @click="skipTSP">
              <span class="text_21">TSP平台</span>
              <span class="text_22">TSP&nbsp;Platform</span>
              <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="../assets/index/jjfa_tsp@2x.png"
              />
          </div>
          <div class="section_4 flex-col" @click="skipSchemeFleet">
            <span class="text_23">车队管理</span>
            <span class="text_24">Fleet&nbsp;Management</span>
            <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_cdgl@2x.png"
            />
          </div>
          <div class="section_5 flex-col" @click="skipEnergy">
            <span class="text_25">新能源监控(RTM)</span>
            <span class="text_26">New&nbsp;Energy&nbsp;Monitoring(RTM)</span>
            <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_rtm@2x.png"
            />
          </div>
          <div class="section_6 flex-col" @click="skipOTA">
            <div class="text-wrapper_4 flex-row">
              <span class="text_27">OTA升级</span>
            </div>
            <div class="text-wrapper_5 flex-row">
              <span class="text_28">OTA&nbsp;Upgrade</span>
            </div>
            <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_ota@2x.png"
            />
          </div>
          <div class="section_7 flex-col" @click="skipSchemeVideo">
            <span class="text_29">视频监控平台</span>
            <span class="text_30">Video&nbsp;Monitoring&nbsp;Platform</span>
            <img
                class="image_9"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_spjkpt@2x.png"
            />
          </div>
          <div class="section_8 flex-col" @click="skipBigData">
            <div class="text-wrapper_6 flex-row">
              <span class="text_31">大数据</span>
            </div>
            <div class="text-wrapper_7 flex-row">
              <span class="text_32">Big&nbsp;Data</span>
            </div>
            <img
                class="image_10"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_dsj@2x.png"
            />
          </div>
          <div class="section_9 flex-col" @click="skipDirectMall">
            <span class="text_33">直销商城</span>
            <span class="text_34">Direct&nbsp;Selling&nbsp;Mall</span>
            <img
                class="image_11"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_zxsc@2x.png"
            />
          </div>
          <div class="section_10 flex-col" @click="skipPartsMall">
            <div class="text-wrapper_8 flex-row">
              <span class="text_35">配件商城</span>
            </div>
            <div class="text-wrapper_9 flex-row">
              <span class="text_36">Accessories&nbsp;Mall</span>
            </div>
            <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_pjsc@2x.png"
            />
          </div>
          <div class="section_11 flex-col" @click="skipForum">
            <div class="text-wrapper_10 flex-row">
              <span class="text_37">论坛</span>
            </div>
            <div class="text-wrapper_11 flex-row">
              <span class="text_38">Forum</span>
            </div>
            <img
                class="image_13"
                referrerpolicy="no-referrer"
                src="../assets/index/jjfa_lt@2x.png"
            />
          </div>
        </div>

        <div class="grid_2">
          <div class="anli_1">
            <span class="anli_2">项目案例</span>
          </div>
          <div class="anli_3">
            <span class="anli_4">Project Case</span>
          </div>

          <div class="anli_22">
            <div class="anli_5" @click="skipPassenger" :style="backgroundDiv3">
              <div class="anli_7">
                <span class="anli_8">乘用车车联网</span>
                <span class="anli_9">该系统包含Android & ios移动端开发、穿戴设备、后台管理系统等多个用户端，提供寻车、续航里程、车辆状态检测等全方位车联网服务。</span>
              </div>
            </div>

            <div class="anli_10" @click="skipFleet" :style="backgroundDiv4">
              <div class="anli_11">
                <span class="anli_12">车队管理系统</span>
                <span class="anli_13">将车联网打造成串联各智能体系的数字化平台，数据统一归集、统一分发，以此为基础支撑主机厂研产供销的数字化、智能化</span>
              </div>
            </div>

            <div class="anli_14" @click="skipVideo" :style="backgroundDiv5">
              <div class="anli_15">
                <span class="anli_16">视频监控平台</span>
                <span class="anli_17">通过对商用车车辆运行管理、报警监控、视频监控等一系列业务需求，可实现驾驶行为监控报警、敏感区雷达监控等功能。</span>
              </div>
            </div>

            <div class="anli_18" @click="skiCommercial" :style="backgroundDiv6">
              <div class="anli_19">
                <span class="anli_20">商用车车联网</span>
                <span class="anli_21">针对卡车司机开发的一款软件，通过将智能车载终端与卡车绑定，包含实时油耗、车辆轨迹、贴吧论坛、直销商城等贴心服务。</span>
              </div>
            </div>
          </div>
        </div>

        <div class="grid_3">
          <div class="hezuo_1">
            <span class="hezuo_2">合作伙伴</span>
          </div>
          <div class="hezuo_3">
            <span class="hezuo_4">Partners</span>
          </div>
          <div class="hezuo_5" :style="backgroundDiv7"></div>
        </div>

        <order-bottom></order-bottom>

        <order-top></order-top>

        <order-sidebar></order-sidebar>

      </div>
    </div>

  </div>
</template>

<script>
import orderBottom from "@/views/bottom"
import orderTop from "@/views/suspendTop"
import orderSidebar from "@/views/suspendSidebar"

export default {
  name: "HomeView",
  components:{
    orderBottom,orderTop,orderSidebar
  },
  beforeRouteEnter(to, from, next) {
    // 添加背景色 margin:0;padding:0是为了解决vue四周有白边的问题
    document.querySelector('body').setAttribute('style', 'margin:0;padding:0px;')
    next()
  },
  beforeRouteLeave(to, from, next) {
    // 去除背景色
    document.querySelector('body').setAttribute('style', '')
    next()
  },
  data(){
    return {
      imgList: [
        {imgUrl: require('../assets/index/banner1.png')},
        {imgUrl: require('../assets/index/banner1111.png')},
      ],
      leftVal:0,  // 轮播图盒子的偏移值
      flag:true, // 用来节流防止重复点击
      start:null, // 自动执行下一张定的时器
      imgWidth:6520,
      // imgWidth:1920,
      // imgHeight: 620,
      ition:2.2, // 设置轮播图过度时间
      imgShow:0, // 表示当前显示的图片索引

      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/index/banner1.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv1: {
        backgroundImage: 'url(' + require('@/assets/index/hzzx_ffrx@2x.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv2: {
        backgroundImage: 'url(' + require('@/assets/index/hzzx_qyyx@2x.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv3: {
        backgroundImage: 'url(' + require('@/assets/index/alzs_cycclw.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv4: {
        backgroundImage: 'url(' + require('@/assets/index/cheduiguanli.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv5: {
        backgroundImage: 'url(' + require('@/assets/index/alzs_qczkp.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv6: {
        backgroundImage: 'url(' + require('@/assets/index/shangyongche.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
      backgroundDiv7: {
        backgroundImage: 'url(' + require('@/assets/index/huoban.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      },
    };
  },
  created() {

  },
  methods:{
    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun(type){// 停止定时器            // 重新执行定时器
      type=='移入'?clearTimeout(this.start):this.aoli()
    },
    // 此为自动轮播定时器
    aoli(){
      this.start = setInterval(()=>{
        this.NextFun()
      },2000)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle(fun) {
      if (this.flag) {
        this.flag = false;
        fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true;
        }, 1700); // 节流间隔时间
      }
    },
    // 上一张
    PrevFun(){
      if(this.leftVal==0){ // 判断显示的图片 是 第一张时执行
        // this.imgList.length是指循环图片数组的图片个数
        this.ition=0 // 将过渡时间变成0，瞬间位移到最后一张图
        this.imgShow=this.imgList.length-1 // 将高亮小点改为最后一张图
        this.leftVal=(this.imgList.length)*this.imgWidth // 瞬间移动
        setTimeout(()=>{  // 通过延时障眼法,归原过渡时间,执行真正的“上一张”函数
          this.ition=0.8
          this.leftVal -= this.imgWidth
        },this.ition*1000)
      }else{ // 判断显示的图片 不是 第一张时执行
        this.ition=0.8
        this.leftVal -= this.imgWidth
        this.imgShow--
      }
    },
    // 下一张
    NextFun(){
      if(this.leftVal==(this.imgList.length-1)*this.imgWidth){ // 判断显示的图片 是 最后一张时执行
        this.ition=0.8
        this.leftVal+=this.imgWidth
        this.imgShow=0
        setTimeout(()=>{
          this.ition=0
          this.leftVal=0
        },this.ition*1000)
      }else{ // 判断显示的图片 不是 最后一张时执行
        this.ition=0.8
        this.leftVal+=this.imgWidth
        this.imgShow++
      }
    },
    // 点击小圆点
    instFun(index){
      this.ition=0.8
      this.leftVal=index*this.imgWidth
      this.imgShow=index
    },

    skipAbout(){
      this.$router.push('/about');
    },
    skipHome(){
      this.$router.push('/');
    },
    skipPassenger(){
      this.$router.push('/passenger')
    },
    skipVideo(){
      this.$router.push('/video')
    },
    skipTSP(){
      this.$router.push('/TSP')
    },
    skipFleet(){
      this.$router.push('/fleet')
    },
    skiCommercial(){
      this.$router.push('/commercial')
    },
    skipSchemeFleet(){
      this.$router.push('/schemeFleet')
    },
    skipEnergy(){
      this.$router.push('/energy')
    },
    skipOTA(){
      this.$router.push('/OTA')
    },
    skipSchemeVideo(){
      this.$router.push('/schemeVideo')
    },
    skipBigData(){
      this.$router.push('/data')
    },
    skipDirectMall(){
      this.$router.push('/directMall')
    },
    skipPartsMall(){
      this.$router.push('/partsMall')
    },
    skipForum(){
      this.$router.push('/forum')
    },

  }
};
</script>

<style scoped>
.el-button--text.is-active,
.el-button--text:active {
  color: white;
}

.el-button--text:focus,
.el-button--text:hover {
  color: white;
}
.imgBox img{
  flex-shrink:0;
  height: 640px;
  /*width: 1920px;*/
  width: 100%;
}
/* 图片容器样式 */
.SwiperBox {
  position: relative;
  height: 640px;
  /*width: 1920px;*/
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  /*margin-left: -269px;*/
  margin-top: -30px;
}
.imgBox{
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 500px;
  height: 300px;
  display: flex;
  justify-content: flex-start;
}

/* 下方指示器盒子 */
.instBox{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  display: flex;
}
/* 小圆点 */
.inst{
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  margin-right: 8px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.inst:last-child{
  margin-right: 0px;
}
.instActv{
  border: 1px solid #176DB4;
  background: #176DB4;
}
#app{
  /*width: 93%;*/
  width: 100%;
  display: flex;
  justify-content: center;
  /*padding-left: 133px;*/
  padding-top: 30px;
}
.home-page{
  width: 100%;
  height: 3628px;
  background: #FFFFFF;
}
.home-page-picture{
  width: 100%;
  height: 640px;
  /*background: linear-gradient(225deg, rgba(53,109,143,0.5) 0%, rgba(24,59,86,0.75) 100%);*/
}
.login{
  display: flex;
  width: 1140px;
  height: 80px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.login-four{
  display: flex;
  justify-content: right;
  align-items: center;
  position: absolute;
  margin-top: 11px;
  top: 0;
  width: 100%;
}
.login-four-1{
  width: 40px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: 	#A8A8A8;
  line-height: 27px;
  margin-left: 558px;
}
.login-four-2{
  width: 80px;
  height: 40px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A8A8A8;
  line-height: 45px;
  margin-left: 56px;
  text-align: center;
}
.login-four-3{
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #A8A8A8;
  line-height: 28px;
  margin-left: 56px;
  display: flex;
}
.login-four-4{
  width: 100px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-left: 56px;
  color: #A8A8A8;
  line-height: 28px;
}
/*.login-image{*/
/*  height: 36px;*/
/*  position: absolute;*/
/*  width: 9%;*/
/*  top: 30px;*/
/*  left: 411px;*/
/*}*/
.login-center{
  width: 531px;
  height: 204px;
  font-size: 64px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 102px;
  letter-spacing: 2px;
  display: flex;
  text-align: center;
  /*justify-content: center;*/
  /*align-items: center;*/
}
.login-center-1{
  font-family: PingFangSC-Medium, PingFang SC;
  position: absolute;
  margin-left: -364px;
  top: 151px;
  width: 100%;
  padding-left: -300px;
  padding-top: 100px;
}
.login-center-2{
  font-family: PingFangSC-Medium, PingFang SC;
  position: absolute;
  margin-left: -606px;
  top: 255px;
  width: 100%;
  padding-top: 106px;
  padding-left: 310px;
}
.home-page-index{
  width: 100%;
  height: 2967px;
  background: #FFFFFF;
}
.home-page-first{
  width: 100%;
  height: 1072px;
  background-color: #FFFFFF;
}
.home-page-first-1{
  width: 100%;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 50px;
  margin-top: 80px;
}
.home-page-first-2{
  width: 100%;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #CCCCCC;
  line-height: 22px;
}

.grid_1 {
  width: 1140px;
  height: 850px;
  flex-wrap: wrap;
  display: flex;
  margin: 150px auto 0 auto;
}
.grid_2{
  width: 100%;
  height: 873px;
  background-color: #F0F0F0;
}
.grid_3{
  width: 100%;
  height: 571px;
  background-color: white;
}

.anli_1{
  line-height: 40px;
  width: 100%;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 50px;
  margin-top: 60px;
  padding-top: 70px;
}
.anli_2{
  font-size: 30px;
  font-family: PingFangSC-Medium;
  position: relative;
  margin: 0px 1000px 94px 0px;
  font-weight: 500;
}
.anli_3{
  width: 100%;
  height: 50px;
  font-size: 0px;
  font-weight: 500;
  color: #222222;
  line-height: 50px;
  margin-left: -24px;
  padding-top: -50px;
}
.anli_4{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #CCCCCC;
  position: relative;
  margin: 0px 1000px 94px 0px;
  line-height: 0px;
}
.anli_5{
  width: 555px;
  height: 280px;
  margin-left: 200px;
}
.anli_7{
  margin-left: 20px;
  margin-top: -100px;
  display: inline-grid;
  width: 522px;
  margin-top: 195px;
}
.anli_8{
  width: 146px;
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 32px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
}
.anli_9{
  width: 475px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  line-height: 20px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
  margin-left: 2px;
  text-align: left;
}
.anli_10{
  width: 555px;
  height: 280px;
  margin-left: 780px;
  margin-top: -280px;
}
.anli_11{
  margin-left: 20px;
  margin-top: -100px;
  display: inline-grid;
  width: 522px;
  margin-top: 195px;
}
.anli_12{
  width: 146px;
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 32px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
}
.anli_13{
  width: 475px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  line-height: 20px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
  margin-left: 2px;
  text-align: left;
}
.anli_14{
  width: 555px;
  height: 280px;
  margin-left: 200px;
  margin-top: 40px;
}
.anli_15{
  /*margin-left: 20px;*/
  margin-top: -100px;
  display: inline-grid;
  /*width: 522px;*/
  margin-top: 128px;
  /*margin-top: 195px;*/
  width: 555px;
  height: 152px;
  background: linear-gradient(180deg, rgba(24,59,86,0) 0%, #152532 100%);
  box-shadow: 0px 20px 50px 0px rgba(15,24,44,0.15);
  border-radius: 2px;
  opacity: 0.75;
}
.anli_16{
  width: 196px;
  height: 88px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 166px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
}
.anli_17{
  width: 466px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  line-height: 20px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
  margin-left: 26px;
  text-align: left;
}
.anli_18{
  width: 555px;
  height: 280px;
  margin-left: 780px;
  margin-top: -280px;
}
.anli_19{
  /*margin-left: 0px;*/
  /*margin-top: -100px;*/
  display: inline-grid;
  /*width: 522px;*/
  margin-top: 140px;
  width: 555px;
  height: 140px;
  background: linear-gradient(180deg, rgba(24,59,86,0) 0%, #152532 100%);
  box-shadow: 0px 20px 50px 0px rgba(15,24,44,0.15);
  border-radius: 2px;
  opacity: 0.75;
}
.anli_20{
  width: 200px;
  height: 32px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 136px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
}
.anli_21{
  width: 475px;
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  line-height: 20px;
  text-shadow: 0px 20px 50px rgba(15,24,44,0.15);
  margin-left: 28px;
  margin-top: 42px;
  text-align: left;
}
.anli_22{
  display: inline-block;
  margin-left: -180px;
}
.hezuo_1{
  line-height: 40px;
  width: 100%;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 50px;
  margin-top: -10px;
  padding-top: 70px;
}
.hezuo_2{
  font-size: 30px;
  font-family: PingFangSC-Medium;
  position: relative;
  margin: 0px 1000px 94px 0px;
  font-weight: 500;
}
.hezuo_3{
  width: 100%;
  height: 50px;
  font-size: 0px;
  font-weight: 500;
  color: #222222;
  line-height: 50px;
  margin-left: -34px;
  padding-top: -50px;
}
.hezuo_4{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #CCCCCC;
  position: relative;
  margin: 0px 1000px 94px 0px;
  line-height: 0px;
}
.hezuo_5{
  width: 1156px;
  height: 353px;
  display: inline-block;
  /*margin-left: 44px;*/
}

.section_3 {
  height: 172px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng250ef567f65ca48961f2d9eac4bde29dbf41560fe247cf3796a447e139913463) -8px -4px
  no-repeat;
  background-size: 380px 188px;
  width: 364px;
  position: relative;
  margin: 0 24px 86px 0;
}
.anli_5:hover {
  transform: scale(1.02);
}
.anli_10:hover {
  transform: scale(1.02);
}
.anli_14:hover {
  transform: scale(1.02);
}
.anli_18:hover {
  transform: scale(1.02);
}
.section_3:hover img{
  transform: scale(1.07);
}
.section_4:hover img{
  transform: scale(1.07);
}
.section_5:hover img{
  transform: scale(1.07);
}
.section_6:hover img{
  transform: scale(1.07);
}
.section_7:hover img{
  transform: scale(1.07);
}
.section_8:hover img{
  transform: scale(1.07);
}
.section_9:hover img{
  transform: scale(1.07);
}
.section_10:hover img{
  transform: scale(1.07);
}
.section_11:hover img{
  transform: scale(1.07);
}
.text-wrapper_2 {
  width: 96px;
  height: 32px;
  margin: 84px 0 0 134px;
}
.text_21 {
  width: 96px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 211px;
  padding-top: 126px;
  margin-left: 135px;
  position: absolute;
  left: 0px;
  top: -130px;
}
.text-wrapper_3 {
  width: 89px;
  height: 28px;
  margin: 5px 0 23px 257px;
}
.text_22 {
  width: 108px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  margin: 0px 40px 23px 222px;
  margin-left: -253px;
  position: absolute;
  left: 365px;
  top: 120px;
}
.image-wrapper_1 {
  height: 122px;
  /*background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf284ddc500d055e7ff4f9ad80097d2f2ee1e25c66d62a81ae9c62c4a72f697ca)*/
  /*100% no-repeat;*/
  background-size: 100% 100%;
  width: 118px;
  position: absolute;
  left: 123px;
  top: -54px;
}
.label_2 {
  width: 122px;
  height: 119px;
  margin: -56px -240px 47px -253px
}
.section_4 {
  position: relative;
  width: 364px;
  height: 172px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng250ef567f65ca48961f2d9eac4bde29dbf41560fe247cf3796a447e139913463) -8px -4px
  no-repeat;
  background-size: 380px 188px;
  margin: 0 24px 86px 0;
}
.text_23 {
  width: 98px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
  margin: 84px 0 0 134px;
  margin-left: -4px;
  position: absolute;
  left: 138px;
  top: 0px;
}
.text_24 {
  width: 134px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  margin: 5px 28px 23px 222px;
  margin-left: 10px;
  position: absolute;
  top: 113px;
  left: 93px;
}
.image_6 {
  position: absolute;
  left: 121px;
  top: -54px;
  width: 122px;
  height: 119px;
}
.section_5 {
  position: relative;
  width: 364px;
  height: 172px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng250ef567f65ca48961f2d9eac4bde29dbf41560fe247cf3796a447e139913463) -8px -4px
  no-repeat;
  background-size: 380px 188px;
  margin-bottom: 86px;
}
.text_25 {
  width: 192px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
  margin: 84px 0 0 86px;
  position: absolute;
  left: 6px;
}
.text_26 {
  width: 220px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  margin: 5px 45px 23px 150px;
  position: absolute;
  top: 114px;
  left: -93px;
}
.image_7 {
  position: absolute;
  left: 119px;
  top: -53px;
  width: 126px;
  height: 118px;
}
.section_6 {
  height: 172px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng250ef567f65ca48961f2d9eac4bde29dbf41560fe247cf3796a447e139913463) -8px -4px
  no-repeat;
  background-size: 380px 188px;
  width: 364px;
  position: relative;
  margin: 0 24px 86px 0;
}
.text-wrapper_4 {
  width: 99px;
  height: 32px;
  margin: 84px 0 0 134px;
}
.text_27 {
  width: 99px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
}
.text-wrapper_5 {
  width: 90px;
  height: 28px;
  margin: 5px 0 23px 256px;
}
.text_28 {
  width: 110px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  position: absolute;
  left: 111px;
  margin: 0 32px 0 0;
}
.image_8 {
  position: absolute;
  left: 120px;
  top: -51px;
  width: 123px;
  height: 116px;
}
.section_7 {
  position: relative;
  width: 364px;
  height: 172px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng250ef567f65ca48961f2d9eac4bde29dbf41560fe247cf3796a447e139913463) -8px -4px
  no-repeat;
  background-size: 380px 188px;
  margin: 0 24px 86px 0;
}
.text_29 {
  width: 146px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
  margin: 84px 0 0 110px;
  position: absolute;
  left: 0;
}
.text_30 {
  width: 188px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  margin: 5px 37px 23px 170px;
  position: absolute;
  left: -96px;
  top: 116px;
}
.image_9 {
  position: absolute;
  left: 122px;
  top: -54px;
  width: 121px;
  height: 122px;
}
.section_8 {
  height: 172px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng250ef567f65ca48961f2d9eac4bde29dbf41560fe247cf3796a447e139913463) -8px -4px
  no-repeat;
  background-size: 380px 188px;
  margin-bottom: 86px;
  width: 364px;
  position: relative;
}
.text-wrapper_6 {
  width: 74px;
  height: 32px;
  margin: 84px 0 0 146px;
}
.text_31 {
  width: 74px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
}
.text-wrapper_7 {
  width: 58px;
  height: 28px;
  margin: 5px 0 23px 288px;
}
.text_32 {
  width: 87px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  position: absolute;
  left: 121px;
  margin: 0px 20px 0 0;
}
.image_10 {
  position: absolute;
  left: 121px;
  top: -54px;
  width: 122px;
  height: 120px;
}
.section_9 {
  position: relative;
  width: 364px;
  height: 200px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2824f4a38f859d6353b35e031953c97a8af2303b720017effbec6067e76aca63) -8px -4px
  no-repeat;
  background-size: 380px 216px;
  margin: 0 24px 86px 0;
}
.text_33 {
  width: 97px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
  margin: 112px 0 0 134px;
  position: absolute;
  left: 0;
}
.text_34 {
  width: 136px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  margin: 5px 40px 23px 225px;
  position: absolute;
  left: -123px;
  top: 149px;
}
.image_11 {
  position: absolute;
  left: 121px;
  top: -54px;
  width: 121px;
  height: 122px;
}
.section_10 {
  height: 200px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2824f4a38f859d6353b35e031953c97a8af2303b720017effbec6067e76aca63) -8px -4px
  no-repeat;
  background-size: 380px 216px;
  width: 364px;
  position: relative;
  margin: 0 24px 86px 0;
}
.text-wrapper_8 {
  width: 97px;
  height: 32px;
  margin: 112px 0 0 134px;
}
.text_35 {
  width: 97px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
}
.text-wrapper_9 {
  width: 113px;
  height: 28px;
  margin: 5px 0 23px 233px;
}
.text_36 {
  width: 129px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  position: absolute;
  margin: 0 33px 0 0;
  left: 101px;
}
.image_12 {
  position: absolute;
  left: 122px;
  top: -54px;
  width: 121px;
  height: 122px;
}
.section_11 {
  height: 200px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2824f4a38f859d6353b35e031953c97a8af2303b720017effbec6067e76aca63) -8px -4px
  no-repeat;
  background-size: 380px 216px;
  margin-bottom: 86px;
  width: 364px;
  position: relative;
}
.text-wrapper_10 {
  width: 49px;
  height: 32px;
  margin: 112px 0 0 158px;
}
.text_37 {
  width: 49px;
  height: 32px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  white-space: nowrap;
  line-height: 32px;
}
.text-wrapper_11 {
  width: 43px;
  height: 28px;
  margin: 5px 0 23px 303px;
}
.text_38 {
  width: 72px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: right;
  line-height: 28px;
  position: absolute;
  margin: 0 35px 0 0;
  left: 131px
}
.image_13 {
  position: absolute;
  left: 121px;
  top: -54px;
  width: 123px;
  height: 122px;
}
::v-deep .login-image[data-v-9ea40744] {
  height: 36px;
  position: absolute;
  width: 9%;
  top: 30px;
  left: 380px;
}
/*::v-deep .login-image-1[data-v-9ea40744] {*/
/*  width: 117px;*/
/*  height: 0px;*/
/*}*/
.login-image-1{
  width: 116px;
  height: 0px;
}
</style>
