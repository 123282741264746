import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/passenger',
    name: 'passenger',
    component: () => import(/* webpackChunkName: "about" */ '../views/passengerCars.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "about" */ '../views/videoSurveillance.vue')
  },
  {
    path: '/commercial',
    name: 'commercial',
    component: () => import(/* webpackChunkName: "about" */ '../views/commercialVehicle')
  },
  {
    path: '/fleet',
    name: 'fleet',
    component: () => import(/* webpackChunkName: "about" */ '../views/fleetManagement.vue')
  },
  {
    path: '/TSP',
    name: 'TSP',
    component: () => import(/* webpackChunkName: "about" */ '../views/TSPView.vue')
  },
  {
    path: '/schemeFleet',
    name: 'schemeFleet',
    component: () => import(/* webpackChunkName: "about" */ '../views/schemeFleetManagement.vue')
  },
  {
    path: '/energy',
    name: 'energy',
    component: () => import(/* webpackChunkName: "about" */ '../views/newEnergy.vue')
  },
  {
    path: '/OTA',
    name: 'OTA',
    component: () => import(/* webpackChunkName: "about" */ '../views/OTAUpgrade')
  },
  {
    path: '/schemeVideo',
    name: 'schemeVideo',
    component: () => import(/* webpackChunkName: "about" */ '../views/schemeVideoSurveillance')
  },
  {
    path: '/data',
    name: 'data',
    component: () => import(/* webpackChunkName: "about" */ '../views/bigData')
  },
  {
    path: '/directMall',
    name: 'directMall',
    component: () => import(/* webpackChunkName: "about" */ '../views/directMall')
  },
  {
    path: '/partsMall',
    name: 'partsMall',
    component: () => import(/* webpackChunkName: "about" */ '../views/partsMall')
  },
  {
    path: '/forum',
    name: 'forum',
    component: () => import(/* webpackChunkName: "about" */ '../views/useForum')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
